import xssFilter from "xssfilter-js";
import App from '../common.js' // 引入调用接口公共方法
export default {
  inputFilfter(value, status) {
    switch (status) {
      case 1: //只能输入字母和数字
        return value.replace(/[^\w]/g, "");
        break;
      case 2: //只能输入数字
        return value.replace(/[^\d]/g, "");
        break;
      case 3: //判断固话，只能输入+-和数字
        return value.replace(/[^\d\-\+]/g, "");
        break;
      case 4: //只能输入数字，2位有效小数
        value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
        value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        value = value
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
        if (value.indexOf(".") < 0 && value != "") {
          //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          value = parseFloat(value);
        }
        return value;
        break;
    }
  },
  getNowFormatDate(needYear = false) {
    //为导出的文件添加日期
    let date = new Date();
    let seperator1 = "";
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }

    let currentdate =
      needYear == true
        ? year + month + seperator1 + strDate
        : month + seperator1 + strDate;
    return currentdate;
  },
  sessionStorage: {
    storage: window.sessionStorage,
    getItem: function (key) {
      let value = this.storage.getItem(key);
      if (value === null) {
        return false;
      }
      if (
        (value && value.substring(0, 1) === "{") ||
        value.substring(0, 1) == "["
      ) {
        // 判断字符串是否是对象或则数组结构
        value = JSON.parse(value); //将字符串转成json
      }
      return value;
    },
    setItem: function (key, value) {
      if (this.getType(value) === "Object" || this.getType(value) === "Array") {
        value = JSON.stringify(value);
      }
      this.storage.setItem(key, value);
    },
    // 删除项
    removeItem: function (key) {
      this.getItem(key) && this.storage.removeItem(key);
    },
    //  清空
    clear: function () {
      this.storage.clear(); // 清空本地存储
    },
    getType: (object) => {
      /**
       * 方法来源：prototype.js
       * getType(5); // => "Number"
       * getType({}); // => "Object"
       * getType(/foo/); // => "RegExp"
       * getType(''); // => "String"
       * getType(true); // => "Boolean"
       * getType([]); // => "Array"
       * getType(undefined); // => "Window"
       * getType(Element); // => "Constructor"
       **/
      return Object.prototype.toString
        .call(object)
        .match(/^\[object\s(.*)\]$/)[1];
    },
  },
  arrayEquals(array1, array2) {
    //对比数组是否相等
    Array.prototype.equals = function (array) {
      // if the other array is a falsy value, return
      if (!array) return false;

      // compare lengths - can save a lot of time
      if (this.length != array.length) return false;

      for (let i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
          // recurse into the nested arrays
          if (!this[i].equals(array[i])) return false;
        } else if (this[i] != array[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    };
    return array1.equals(array2);
  },
  getDays(strDateStart, strDateEnd) {
    //判断两个日期相差的天数
    let strSeparator = "-"; //日期分隔符
    let oDate1;
    let oDate2;
    let iDays;
    oDate1 = strDateStart.substr(0, 10).split(strSeparator);
    oDate2 = strDateEnd.substr(0, 10).split(strSeparator);
    let strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
    let strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
    iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数
    return iDays + 1;
  },
  getDate(type, timeData) {
    //统计时间范围以及计算相隔天数
    let _mydate = new Date();
    let _myDay =
      _mydate.getDate() < 10 ? `0${_mydate.getDate()}` : _mydate.getDate();
    let _myMonth = _mydate.getMonth() + 1;
    let _nowDate = `${_mydate.getFullYear()}-${
      _myMonth < 10 ? `0${_myMonth}` : _myMonth
    }-${_myDay}`; //当期日期
    let _day = 0; //相差天数
    switch (type) {
      case "today":
        _day = 1;

        break;
      case "week":
        //计算周
        _day = _mydate.getDay() === 0 ? 7 : _mydate.getDay();
        _mydate.setDate(_mydate.getDate() - _day + 1);
        // 如果当期天数小于相差天数，则月份要减1
        _myMonth = _mydate.getDate() < _day ? _myMonth - 1 : _myMonth;
        break;
      case "month":
        // 计算月
        _day = _myDay;
        _mydate.setDate(_mydate.getDate() - _myDay + 1);
        break;
      case "year":
        // 计算年
        let dateArr = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth(); //getMonth()是从0开始
        let year = date.getFullYear();
        for (let i = 0; i < month; i++) {
          _day += dateArr[i];
        }
        _day += day;
        //判断是否闰年
        if (
          (month > 1 && year % 4 == 0 && year % 100 != 0) ||
          year % 400 == 0
        ) {
          _day += 1;
        }
        _myMonth = 1;
        _mydate.setDate(_mydate.getDate() - _day + 1);
        break;
      case "time":
        //传入时间
        return {
          beginDate: timeData.beginDate,
          endDate: timeData.endDate,
          days: this.getDays(timeData.beginDate, timeData.endDate),
        };
        break;
      default:
        break;
    }
    _myMonth = _myMonth < 10 ? `0${_myMonth}` : _myMonth;

    return {
      beginDate: `${_mydate.getFullYear()}-${_myMonth}-${
        _mydate.getDate() < 10 ? `0${_mydate.getDate()}` : _mydate.getDate()
      }`,
      endDate: _nowDate,
      days: _day,
    };
  },
  countDay(time, day) {
    //将传入的日期进行加减
    let _time = new Date(
      Date.parse(new Date(time)) + (day - 1) * 24 * 60 * 60 * 1000
    );

    var year = _time.getFullYear();
    var month = _time.getMonth() + 1;
    var date = _time.getDate();

    return `${year}-${month > 10 ? month : "0" + month}-${
      date > 10 ? date : "0" + date
    }`;
  },
  digitTransform(value, type) {
    //将传进来的数值进行取整，type==1时数值加逗号
    return type
      ? parseFloat(parseFloat(value).toFixed(0)).toLocaleString()
      : parseFloat(value).toFixed(0);
  },
  getQueryString(history, name) {
    //解析url中的字段
    // debugger
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = history.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  },
  getQueryValue(name) {
    //解析url中的字段
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r1 = window.location.search.substr(1).match(reg);
    var hash = "";
    var arr = window.location.hash.split("?");
    if (arr.length > 1) {
      hash = arr[1];
    }
    var r2 = hash.match(reg);
    var r = r1 || r2;
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  },
  formatSeconds(value) {
    //秒转换为时分秒
    let result = parseInt(Number(value));
    let h =
      Math.floor(result / 3600) < 10
        ? "0" + Math.floor(result / 3600)
        : Math.floor(result / 3600);
    let m =
      Math.floor((result / 60) % 60) < 10
        ? "0" + Math.floor((result / 60) % 60)
        : Math.floor((result / 60) % 60);
    let s =
      Math.floor(result % 60) < 10
        ? "0" + Math.floor(result % 60)
        : Math.floor(result % 60);
    let res = "";
    res += `${h}:`;
    res += `${m}:`;
    res += `${s}`;
    return res;
  },
  date(value, fmt = "yyyy-MM-dd hh:mm:ss") {
    if (!value) {
      return value;
    }
    let date = new Date(value - 0);
    let o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    }
    return fmt;
  },
  timeSplit(value1, value2) {
    //判断两个时间是否同一天，同一天则不要年月日
    if (!value1) {
      return value1;
    }
    let time1 = value1.split(" ");
    let time2 = value2.split(" ");
    if (time1[0] === time2[0]) {
      return time2[1];
    } else {
      return value2;
    }
  },
  isDuringDate: function (beginDateStr, endDateStr) {
    //判断当前时间是否在某个时间区间内
    var curDate = new Date(),
      beginDate = new Date(beginDateStr),
      endDate = new Date(endDateStr);
    if (curDate >= beginDate && curDate <= endDate) {
      return true;
    }
    return false;
  },
  commentXssFilter(content) {
    //论坛的发布内容回显后需要过滤
    let inf = new xssFilter.InjectFilter({
      xss: true,
    });
    let xssStr = inf.filter(content);
    xssStr = xssStr.replace(/&amp;lt;/g, "<label>&lt;</label>");
    xssStr = xssStr.replace(/&amp;gt;/g, "<label>&gt;</label>");
    return xssStr;
  },
  stopTime(time){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        resolve()
      }, time)
    })
  },
  // 中文字符串截取
  addCHStrProtoType: function () {
    String.prototype.strLen = function () {
      var len = 0;
      for (var i = 0; i < this.length; i++) {
        if (this.charCodeAt(i) > 255 || this.charCodeAt(i) < 0) len += 2;
        else len++;
      }
      return len;
    };
    //将字符串拆成字符，并存到数组中
    String.prototype.strToChars = function () {
      var chars = new Array();
      for (var i = 0; i < this.length; i++) {
        chars[i] = [this.substr(i, 1), this.isCHS(i)];
      }
      String.prototype.charsArray = chars;
      return chars;
    };
    //判断某个字符是否是汉字
    String.prototype.isCHS = function (i) {
      if (this.charCodeAt(i) > 255 || this.charCodeAt(i) < 0) return true;
      else return false;
    };
    //截取字符串（从start字节到end字节）
    String.prototype.subCHString = function (start, end) {
      var len = 0;
      var str = "";
      this.strToChars();
      for (var i = 0; i < this.length; i++) {
        if (this.charsArray[i][1]) len += 2;
        else len++;
        if (end < len) return str;
        else if (start < len) str += this.charsArray[i][0];
      }
      return str;
    };
    //截取字符串（从start字节截取length个字节）
    String.prototype.subCHStr = function (start, length) {
      return this.subCHString(start, start + length);
    };
  },
  getUserInfoByToken: async function (token) {
	  localStorage.removeItem('pcUserInfo')
	  localStorage.setItem('token', token)
	  const getRes = await App.axiosData('post','/yunkai/admin/studentinfo/pc/studentInfo', {})
    console.log('getRes',getRes);
    return new Promise(function (resolve, reject) {
      if (getRes.code === 'REQ001' && getRes.data) {
        localStorage.setItem('pcUserInfo', JSON.stringify(getRes.data))
        resolve(getRes.data);
      } else {
        reject(" sync fail ");
      }
    });
  },
  getUserToken(){
    return new Promise(function (resolve, reject) {
      let token = ''
      let userInfo = localStorage.getItem('pcUserInfo');
      let localToken = localStorage.getItem("token") || window.token || "";
      if (userInfo) { //这些代码是复制yunkaiuniapp的免登陆逻辑
        userInfo = JSON.parse(userInfo)
        token = userInfo.token
      } else if (localToken) {
        token = localToken
      }
      resolve(token);
    });
  }
};
