import React, { Component } from "react";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import NotFount from "./views/NotFound/index";
import LoadableComponent from "./utils/LoadingPage";

// 登录页
const Login = LoadableComponent(() => import("./views/Login/login"));
// 首页
const Index = LoadableComponent(() => import("./views/Study/StudyHomePage"));
// 任务
const Task = LoadableComponent(() => import("./views/Task/Task"));
// 直播
const Live = LoadableComponent(() => import("./views/Liveing/Liveing"));
// 直播视频页
const LiveVideo = LoadableComponent(() =>
  import("./views/LiveVideo/LiveVideo")
);
// 课程-任务详情页
const StudyTaskDetail = LoadableComponent(() =>
  import("./views/Task/taskDetail")
);
// 任务-任务详情页
const TaskDetail = LoadableComponent(() => import("./views/Task/taskDetail"));
// 任务-任务详情页--题库
const TaskAnswer = LoadableComponent(() => import("./views/Task/taskAnswer"));
// 课程--培养方案
const StudyPlan = LoadableComponent(() =>
  import("./views/Study/Views/StudyPlan/StudyPlan")
);
// 学籍
const StudyStatus = LoadableComponent(() =>
  import("./views/StudyStatus/StudyStatus")
);
// 考试
const Exam = LoadableComponent(() => import("./views/Exam/Exam"));
// 毕业
const Graduate = LoadableComponent(() => import("./views/Graduate/Graduate"));
// 测试例子页
const Test = LoadableComponent(() => import("./views/Test/test"));
const ZypTest = LoadableComponent(() => import("./views/Test/zypTest"));
const TestTaskList = LoadableComponent(() =>
  import("./views/Test/testTaskList")
);

const CourseBrief = LoadableComponent(() =>
  import("./views/Study/CourseBrief")
);
const StudyCoursePage = LoadableComponent(() =>
  import("./views/Study/StudyCoursePage")
);

// 视频播放页面
const VideoPlayer = LoadableComponent(() =>
  import("./views/StudyVideo/StudyVideo")
);

// 消息中心
const MessageList = LoadableComponent(() =>
  import("./views/Message/MessageList")
);

// 社区首页
const CommunityIndex = LoadableComponent(() =>
  import("./views/Community/index")
);
// 我的主页
const MyHomeIndex = LoadableComponent(() =>
  import("./views/MyHome/index")
);

// 预约详情页
const Reservation = LoadableComponent(() =>
  import("./views/MyHome/MyExam/reservation")
);

// 主题详情
const ThemeDetail = LoadableComponent(() =>
  import("./views/Community/ThemeDetail/ThemeDetail")
);
const MyCommunityHomePage = LoadableComponent(() =>
  import("./views/Community/MyCommunityHomePage/MyCommunityHomePage")
);
// 他的社区
const OtherCommunityHomePage = LoadableComponent(() =>
  import("./views/Community/OtherCommunityHomePage/OtherCommunityHomePage")
);
// 查看合同
const MyHomeSeeContract = LoadableComponent(() =>
  import("./views/MyHome/Contract/SeeContract")
);



class Page extends Component {
  componentDidMount() { }
  routerView = () => {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    if (!flag) {
      //为电脑端才让访问，移动端限制访问 // todo跳h5
      return (
        <Switch>
          <Route component={NotFount} />
        </Switch>
      );
    } else {
      return <div></div>;
    }
  };
  render() {
    return (
      <Router>
        {/*this.routerView()*/}
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/Index" push />}
          ></Route>
          <Route path="/404" component={NotFount}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/index" component={Index}></Route>
          <Route exact path="/task/taskDetail" component={TaskDetail}></Route>
          <Route exact path="/task/taskAnswer" component={TaskAnswer}></Route>
          <Route
            exact
            path="/study/taskDetail"
            component={StudyTaskDetail}
          ></Route>
          <Route exact path="/task" component={Task}></Route>
          <Route exact path="/live" component={Live}></Route>
          <Route exact path="/liveVideo" component={LiveVideo}></Route>
          <Route exact path="/studyPlan" component={StudyPlan}></Route>
          <Route exact path="/test" component={Test}></Route>
          <Route exact path="/testTask" component={TestTaskList}></Route>
          <Route exact path="/studyStatus" component={StudyStatus}></Route>
          <Route exact path="/exam" component={Exam}></Route>
          <Route exact path="/graduate" component={Graduate}></Route>
          <Route exact path="/video" component={VideoPlayer}></Route>
          <Route exact path="/coursePage" component={StudyCoursePage}></Route>
          <Route exact path="/courseBrief" component={CourseBrief}></Route>
          <Route exact path="/message" component={MessageList}></Route>
          <Route exact path="/zypTest" component={ZypTest}></Route>
          <Route exact path="/community" component={CommunityIndex}></Route>
          <Route exact path="/myHome" component={MyHomeIndex}></Route>
          <Route exact path="/myHome/reservation" component={Reservation}></Route>
          <Route exact path="/community/ThemeDetail" component={ThemeDetail}></Route>
          <Route exact path="/zypTest" component={ZypTest}></Route>
          <Route exact path="/community/myHomePage" component={MyCommunityHomePage}></Route>
          <Route exact path="/community/otherHomePage" component={OtherCommunityHomePage} ></Route>
          <Route exact path="/contract/seeContract" component={MyHomeSeeContract} ></Route>
          {/* <Route component={NotFount} /> */}
          {/* <Redirect to="/Index" /> */}
        </Switch>
      </Router>
    );
  }
}

export default Page;
