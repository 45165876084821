/**
 * Created by 彬 on 2023.2.02.
 */
import { combineReducers } from 'redux';
import studyInfo from './course/studyInfo';


export default combineReducers({
    studyInfo
});
